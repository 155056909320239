// Library Imports
import React, { Component } from "react";
import $ from "jquery";
import ReactHTMLParser from "react-html-parser";
// Service Imports
import { LoginService } from "../../services/login.service";
// Other Imports
import { 
  warningNotification, warning, 
} from '../../components/notification/notifications';
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
// Asset Import
const Finxlogo = require("../../assets/images/finxlogo1.png");
  
//! Unused Imports
// import { Global_var } from "../../global/global_var";
// import Finxlogo from "../../assets/images/finxlogo.png";
// import Finxlogo from '../../assets/images/finxlogo1.png';
// import UserImage from "../../assets/images/user-img.png";
// import Opsdashboardlanding from "../../containers/finxdashboardlanding/opsdashboardlanding";

class Opsheader extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      alertcount: '',
      notificationcount: '',
      flipalert: false,
      alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
      notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
    }
    //localStorage.setItem('checkedtab',false);
  }

  componentDidMount() {
    $(window).blur(function () {
      window.addEventListener('storage', e => {
        if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {
          if (localStorage.getItem("windowloggedout") === false) {
          } else {
            window.location.href = '/';
          }
        }
        e.currentTarget.removeEventListener("storage", e)
      });
    });
    if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
      if ([5,'5'].includes(localStorage.getItem('roleId'))) {
        if (localStorage.getItem('checkedtab') === false) {
          localStorage.setItem('checkedtab', true);
          window.location.replace('/studentDashboard');
        }
      } else if([6,'6'].includes(localStorage.getItem('roleId'))){
        if (localStorage.getItem('checkedtab') === false) {
          localStorage.setItem('checkedtab', true);
          window.location.replace('/ops-dashboard');
        }
      }
      else {
        if (localStorage.getItem('checkedtab') === false) {
          localStorage.setItem('checkedtab', true);
          window.location.replace('/admin-dashboard');
        }
      }
    }
    else {
      if (localStorage.getItem('checkedtab') === 'false') {
        localStorage.setItem('checkedtab', false);
        window.location.href = '/';
      }
    }
    var currpage = window.location.href;
    if (currpage.substring(currpage.lastIndexOf('/') + 1) === 'admin-user-list') {
      this.setState({
        flipalert: true,
      })
    }
    $(document).ready(function () {
      $(".popup").click(function () {
        $("#myPopup").toggleClass("show");
        setTimeout(function () {
          $('#myPopup').toggleClass('show');
        }, 2000)
      });

      $(".popup1").click(function () {
        $("#myPopup1").toggleClass("show");
        setTimeout(function () {
          $('#myPopup1').toggleClass('show');
        }, 2000)
      });

      $(".popup2").click(function () {
        $("#myPopup2").toggleClass("show");
        setTimeout(function () {
          $('#myPopup2').toggleClass('show');
        }, 2000)
      });

      $(".popup3").click(function () {
        $("#myPopup3").toggleClass("show");
        setTimeout(function () {
          $('#myPopup3').toggleClass('show');
        }, 2000)
      });

      $(".popup4").click(function () {
        $("#myPopup4").toggleClass("show");
        setTimeout(function () {
          $('#myPopup4').toggleClass('show');
        }, 2000)
      });

      $(".popup5").click(function () {
        $("#myPopup5").toggleClass("show");
        setTimeout(function () {
          $('#myPopup5').toggleClass('show');
        }, 2000)
      });

      $(".popup6").click(function () {
        $("#myPopup6").toggleClass("show");
        setTimeout(function () {
          $('#myPopup6').toggleClass('show');
        }, 2000)
      });

      $(".popup7").click(function () {
        $("#myPopup7").toggleClass("show");
        setTimeout(function () {
          $('#myPopup7').toggleClass('show');
        }, 2000)
      });

      $(".popup8").click(function () {
        $("#myPopup8").toggleClass("show");
        setTimeout(function () {
          $('#myPopup8').toggleClass('show');
        }, 2000)
      });

      $(".popup9").click(function () {
        $("#myPopup9").toggleClass("show");
        setTimeout(function () {
          $('#myPopup9').toggleClass('show');
        }, 2000)
      });
    });

    $(document).ready(function () {
      $("#myBtn").click(function () {
        $("#mySidenav").toggleClass("opensidenav");
        $(".dropdown-container").hide();
      });
    });

    var dropdown = document.getElementsByClassName("dropdown-btn");
    for (var i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        $(".dropdown-container").hide("active");

        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "none") {
          dropdownContent.style.display = "block";
        } else {
          dropdownContent.style.display = "none";
        }
      });
    }


    $('#return-to-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
    });

  }

  alerts = (key) => {
    if (this.state.alertNotificationEvents.length > 0) {
      this.props.history.push("/adminalert", {
        alertid: key,
        alerts: this.state.alertNotificationEvents,
      })
    }
  }
  alert = () => {
    if (this.state.alertNotificationEvents.length > 0) {
      this.props.history.push("/adminalert", {
        alertid: 0,
        alerts: this.state.alertNotificationEvents,
      })
    }
  }
  notifications = () => {
    let notify = JSON.parse(localStorage.getItem('AdminNotification'));
    if (this.state.notificationEvents.length > 0 || notify.length > 0) {
      this.props.history.push("/adminnotification", {
        alertid: 0,
        notification: this.state.notificationEvents || notify
      })
    }
  }

  notification = (key) => {
    let notify = JSON.parse(localStorage.getItem('AdminNotification'));
    if (this.state.notificationEvents.length > 0 || notify.length > 0) {
      this.props.history.push("/adminnotification", {
        alertid: key,
        notification: this.state.notificationEvents || notify
      })
    }
  }

  signout = event => {
    event.preventDefault();
    this.setState({ loading: true });
    if (localStorage.getItem('login') !== null) {
      var data = {
        userName: JSON.parse(localStorage.getItem('login'))["userName"],
        userId: JSON.parse(localStorage.getItem('login'))["userId"]
      };
      LoginService.LogoutUser(data, false, res => {
        if (res.status === "success") {
          this.setState({ loading: false });
          localStorage.clear();
          localStorage.setItem("windowloggedout", true)
          window.location.href = "/";
        } else {
          this.setState({ loading: false });
          warning(res.reasonText, warningNotification);
        }
      });
    } else {
      this.setState({ loading: false });
      window.location.href = '/';
    }
  };




  adminnotifi = () => {
    this.props.history.push("/adminnotification")
  }
  adminale = () => {
    this.props.history.push("/adminalert")
  }
  
  jobList = () =>{
    this.props.history.push("/job-list")
  }

  // candidateList = async (e) =>{
  //   e.preventDefault();
  //   await window.gapi.load("client", async () => {
  //     await window.gapi.client.init({
  //       apiKey: Global_var.URL_API_KEY,
  //       discoveryDocs: [Global_var.URL_DISCOVERY_DOC],
  //     });
  //     this.setState({ gapiInited: true }, () =>
  //       console.log("Client Activated")
  //     );
  //   });
  //   this.props.history.push("/candidate")
  // }
  candidateList = () =>{
    this.props.history.push("/candidate-details")
  }

  bulkJobUpload = () =>{
    this.props.history.push("/bulkJob")
  }
  productl = () => {
    this.props.history.push("/ops-course-list");
   
  };
  videol = () => {
    this.props.history.push("/ops-video-list");
  };
  eventmaster = () => {
    this.props.history.push("/ops-event-list");
  };
  discountmaster = () => {
    this.props.history.push("/ops-discount-list");
  };
  productvaliditymaster = () => {
    this.props.history.push("/ops-product-validity-list");
  };
  adminuserlist = () => {
    this.props.history.push("/admin-user-list");
  };
  dataDumpReport = () => {
    this.props.history.push("/data-dump-report");
  };

  render() {
    var alertdata2 = [];
    var notealertdata2 = [];
    var alertdata = JSON.parse(localStorage.getItem("AdminAlertNotification")) //JSON.parse(localStorage.getItem("alertdata"))
    alertdata2 = JSON.parse(localStorage.getItem("AdminAlertNotification"))
    notealertdata2 = JSON.parse(localStorage.getItem("AdminNotification"))
    var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"))


    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        {/* <!------ header section ----------> */}
        <section className="header">
          <nav className="navbar navbar navbar-fixed-top">
            <span id="myBtn" className="sidenva-icon sidebartogglexyz">
              &#9776;
            </span>

            <div className="container-fluid">
              {/* <!-- Brand/logo --> */}
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/ops-dashboard">
                  <img alt="Finx Logo" src={Finxlogo} />
                </a>
              </div>
              <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-right">
                  <li className="row">
                    <div className="right-search">
                    </div>
                  </li>
                  <li className="nav-item dropdown notification">
                    <a
                      className="nav-link text-light notification"
                      href
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Alerts"
                    >
                      <i className="fa fa-bell-o"></i> Alerts
                    </a>
                    <ul className="dropdown-menu" id="notification-alert">
                      <li className="head text-light bg-dark">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12 col-12">
                            <span>Alerts({(alertdata || []).length ? (alertdata || []).length : (alertdata2 || []).length})</span>
                          </div>
                        </div>
                      </li>
                      <li className="notification-box">
                        <div className="notification-content">
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12">

                              {this.state.flipalert === false ? alertdata && alertdata.slice(0, 3).map(
                                (item, key) =>
                                  (
                                    <div className="notification-content" id={item.alertID} >
                                      <a href onClick={() => this.alerts(item.alertID)} >
                                        <div className="notification-body">
                                          <strong className="text-info">{item.alertName} </strong>

                                        </div>
                                      </a>
                                    </div>
                                  )) : ""}

                              {this.state.flipalert === true ? alertdata2 && alertdata2.slice(0, 3).map(
                                (item, key) =>
                                  (
                                    <div className="notification-content" id={item.alertID} >
                                      <a href onClick={() => this.alerts(item.alertID)} >
                                        <div className="notification-body">
                                          <strong className="text-info">{item.alertName} </strong>

                                        </div>
                                      </a>
                                    </div>
                                  )) : ""}
                            </div>
                          </div>
                        </div>
                      </li>
                      {(alertdata || []).length>0 ? 
                          <li className="footer bg-dark text-center">
                            <a href onClick={this.alert} className="text-light">
                              View All
                          </a>
                          </li>
                        : ""}
                    </ul>
                  </li>

                  <li className="nav-item dropdown notification">
                    <a
                      className="nav-link text-light notification"
                      href
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Messages"
                    >
                      <i className="fa fa-envelope-o" aria-hidden="true"></i> Messages
                    </a>
                    <ul className="dropdown-menu" id="notification-alert">
                      <li className="head text-light bg-dark">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12 col-12">
                            <span>
                              Messages ({(notealertdata || []).length ? (notealertdata || []).length : (notealertdata2 || []).length})
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="notification-box">
                        <div className="row">
                          <div className="panel-body">
                            <div className="col-lg-12 col-sm-12 col-12">
                              {this.state.flipalert === false ? notealertdata && notealertdata.slice(0, 3).map((item, key) =>
                                (
                                  <div className="notification-content" id={item.notifyHistoryId} >
                                    <a href onClick={() => this.notification(item.notifyHistoryId)} >
                                      <div className="notification-body">
                                        <strong className="text-info">{item.fromEmailId}</strong>
                                        <div className="notification-body">
                                          {ReactHTMLParser(item.emailSubjectText)}
                                        </div>
                                        <small className="text-warning">{item.lastAttemptDatetime}</small>
                                      </div>
                                    </a>
                                  </div>
                                )) : ""}

                              {this.state.flipalert === true ? notealertdata2 && notealertdata2.slice(0, 3).map((item, key) =>
                                (
                                  <div className="notification-content" id={item.notifyHistoryId} >
                                    <a href onClick={() => this.notification(item.notifyHistoryId)} >
                                      <div className="notification-body">
                                        <strong className="text-info">{item.fromEmailId}</strong>
                                        <div className="notification-body">
                                          {ReactHTMLParser(item.emailSubjectText)}
                                        </div>
                                        <small className="text-warning">{item.lastAttemptDatetime}</small>
                                      </div>
                                    </a>
                                  </div>
                                )) : ""}

                            </div>

                          </div>
                        </div>
                      </li>
                      <li className="footer bg-dark text-center">
                        <a href onClick={this.notifications} className="text-light">
                          View All
                        </a>
                      </li>
                    </ul>
                  </li>

                 
                  <li className="nav-item dropdown notification">
                    <a
                      href
                      onClick={this.signout}
                      className="nav-link text-light notification"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Sign-Out"
                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!--/.nav-collapse --> */}
            </div>
          </nav>
        </section>

        {/* <!--------------- Left Side navbar -------------> */}
        <div id="mySidenav" className="sidenav sidebartogglexyz">
          <a href="ops-dashboard" className="sidebartogglexyz">
            <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
          </a>
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-user sidebartogglexyz" aria-hidden="true"></i>{" "}
            Masters
            <i className="fa fa-caret-down sidebartogglexyz"></i>
          </button>
          <div className="dropdown-container sidebartogglexyz">
            <a href onClick={this.productl} className="sidebartogglexyz">
              Course
            </a>
            <a href="/corporate-list" className="sidebartogglexyz">
              Corporate
            </a>
            <a href onClick={this.videol} className="sidebartogglexyz">
              Video
            </a>
            <a href onClick={this.discountmaster} className="sidebartogglexyz">
              Discount
            </a>
            <a href onClick={this.eventmaster} className="sidebartogglexyz">
              Event
            </a>
            <a href onClick={this.productvaliditymaster} className="sidebartogglexyz">
              Product Validity
            </a>
          </div>
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-user sidebartogglexyz" aria-hidden="true"></i>{" "}
            Security Management
            <i className="fa fa-caret-down sidebartogglexyz"></i>
          </button>
          <div className="dropdown-container sidebartogglexyz">
            <a href="ops-user-list" className="sidebartogglexyz">
              User
            </a>
          </div> 
       
         
          {[6,'6'].includes(localStorage.getItem('roleId'))?
          <a href onClick={this.jobList} className="sidebartogglexyz">
          <i className="fa fa-book" aria-hidden="true"></i> Job Details
          </a>:''}

          {[6,'6'].includes(localStorage.getItem('roleId'))?
          <a href onClick={this.candidateList} className="sidebartogglexyz">
          <i className="fa fa-book" aria-hidden="true"></i> Candidate Details
          </a>:''}

          {/* {[6,'6'].includes(localStorage.getItem('roleId'))?
          <a href onClick={this.bulkJobUpload} className="sidebartogglexyz">
          <i className="fa fa-upload" aria-hidden="true"></i> Bulk Job Upload
          </a>:''} */}
          <a href="/download-documents" className="sidebartogglexyz">
            <i className="fa fa-upload" aria-hidden="true"></i> Download Documents
          </a>
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-book sidebartogglexyz" aria-hidden="true"></i>{" "}
            Reports
            <i className="fa fa-caret-down sidebartogglexyz"></i>
          </button>
          <div className="dropdown-container sidebartogglexyz">
            <a onClick={this.dataDumpReport} className="sidebartogglexyz" href>
            Data Dump Report
            </a>
          </div>

        </div>

        {/* <!----------- Nav Heading---------------> */}
        <div className="header-shortcut-links navbar-fixed-top">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            {/* <div className="shortcut-links pull-right">
              <ul className="list-inline">
                <li>
                  <a
                    onClick={this.adminuserpro}

                    title="New User"
                  >
                    <i className="fa fa-users" aria-hidden="true"></i>{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={this.adminusercourse}

                    title="New Course"
                  >
                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="popup8"

                    title="New Announcement"
                  >
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <span className="popuptext" id="myPopup8">Coming Soon...</span>
                  </a>
                </li>
                <li>
                  <a
                    className="popup9"

                    title="New events"
                  >
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    <span className="popuptext" id="myPopup9">Coming Soon...</span>
                  </a>
                </li>
              </ul>
            </div>
          */}
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default Opsheader;
